import { Link } from "react-router-dom";
import Logo from "./Logo";
const Footer = () => {
    return (
        <div className=" bg-web-gray text-dark-text  w-full mt-auto ">
            <div className=" w-[65vw] mx-auto">
                <div className="flex justify-between items-center pt-10">
                    <Logo className="text-dark-text" />
                    <div className="flex">
                        <a href="https://linktr.ee/vht_kosice?fbclid=IwAR2vj4M1KvNbTGCuAVitCktumAgSDgJuTaLK4WZNGaaBtSiQmaORjjb19V0" className="">
                            <img src="/img/socials/web.svg" alt="" className="w-5 mx-1" />
                        </a>
                        <a href="https://www.facebook.com/vhtkosice/" className="">
                            <img src="/img/socials/facebook.svg" alt="" className="w-5 mx-1" />
                        </a>
                        <a href="https://www.instagram.com/vht_kosice/" className="">
                            <img src="/img/socials/instagram.svg" alt="" className="w-5 mx-1" />
                        </a>
                    </div>
                </div>
                <div className=" h-[1px] bg-dark-text my-5" />
                <div className="flex justify-between items-end my-5">
                    <div>
                        <p>Saleziáni Tri hôrky</p>
                        <p>Tri hôrky 17</p>
                        <p>040 11 Košice</p>
                        <p>Slovenská republika</p>
                    </div>
                    <div className="flex">
                        <a href="https://3horky.sk" className="">
                            <img src="/img/socials/web.svg" alt="" className="w-5 mx-1" />
                        </a>
                        <a href="https://www.youtube.com/@SalezianiTrihorky" className="">
                            <img src="/img/socials/youtube.svg" alt="" className="w-5 mx-1" />
                        </a>
                        <a href="https://www.facebook.com/3horky" className="">
                            <img src="/img/socials/facebook.svg" alt="" className="w-5 mx-1" />
                        </a>
                        <a href="https://www.instagram.com/3horky/" className="">
                            <img src="/img/socials/instagram.svg" alt="" className="w-5 mx-1" />
                        </a>
                    </div>
                </div>

            </div>
            <div className="bg-[#1e1e1e] z-30 ">
                <div className="w-[65vw] mx-auto py-5 flex justify-between items-center">
                    <div>
                        <p>© Veľký Hokejbalový Turnaj</p>
                        <p>© Saleziáni Tri hôrky</p>
                    </div>
                    <Link to={"/login"}>
                        <img src="/img/admin.svg" alt="" />
                    </Link>
                </div>


            </div>

        </div>
    );
}


export default Footer;