import { Link } from "react-router-dom";
import AdminNavbar from "../../components/AdminNavbar";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import { useYearContext } from "../../hooks/useYearContext";



const Admin = () => {
    const { user } = useAuthContext()
    const { current } = useYearContext()
    const [games, setGames] = useState([])
    const [players, setPlayers] = useState([])
    const [teams, setTeams] = useState([])

    const getGames = async (temp) => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game?year=${current.year}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            setGames(json)


            getTeams(json, temp)
        } else {
            alert(json.error)
        }
    }

    const calculateTeams = (teams, games, players) => {
        teams.forEach(e => {
            e.z = 0
            e.v = 0
            e.p = 0
            e.r = 0
            e.g = 0
            e.ig = 0
            e.b = 0
            e.penalties = 0
            e.WinAgainst = []
        });
        games.forEach(e => {
            let ta = teams.find(x => x.ID === e.team_a_id)
            let tb = teams.find(x => x.ID === e.team_b_id)

            ta.z++;
            tb.z++;

            if (e.winner === ta.ID) {
                ta.v++
                tb.p++
                ta.b += 3
                ta.WinAgainst.push(tb.ID)
            }
            if (e.winner === tb.ID) {
                tb.v++
                ta.p++
                tb.b += 3
                tb.WinAgainst.push(ta.ID)
            }
            if (e.winner === 0 && e.tie === 1) {
                ta.r++
                tb.r++
                ta.b += 1
                tb.b += 1
            }

            ta.g += e.team_a_goals;
            ta.ig += e.team_b_goals

            tb.g += e.team_b_goals;
            tb.ig += e.team_a_goals
        })
        players.forEach(e => {
            e.best_player = 0;
        });
        games.forEach(e => {

            if (e.best_player) {
                players.find(x => x.ID === e.best_player).best_player++;
            }

        });
        players = players.filter(e => {

            if (e.penalties == null) {
                e.penalties = 0
            } else {
                teams.find(x => x.ID === e.team_id).penalties += e.penalties
            }


            return e.penalties + e.goals + e.assists + e.best_player > 0
        })



        setPlayers(players.sort((a, b) => b.best_player - a.best_player || (b.goals + b.assists) - (a.goals + a.assists) || b.goals - a.goals || a.penalties - b.penalties))
        setTeams(teams.sort((a, b) => b.b - a.b || b.penalties - a.penalties || b.WinAgainst.includes(a.ID) - a.WinAgainst.includes(b.ID) || (b.g - b.ig) - (a.g - a.ig) || b.g - a.g))

    }

    const getPlayers = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/player/stats?year=${current.year}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            var temp = json
            setPlayers(temp)
            getGames(temp)
        } else {
            alert(json.error)
        }
    }
    const getTeams = async (tempGames, players) => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/teams?year=${current.year}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        const json = await response.json()
        if (response.ok) {
            calculateTeams(json, tempGames, players)
        } else {
            alert(json.error)
        }
    }
    useEffect(() => {
        if (current) {
            getPlayers();
        }

    }, [current])

    return (
        <div className="flex">
            <AdminNavbar />
            <div className="flex-1  max-w-[100vw] md:max-w-none p-8 ">
                <h1 className="text-3xl mb-5">  Najlepší hráč</h1>
                {(players.length > 0) && <div>
                    <div className="table w-full ">
                        <div className="table-row  child:border-borders text-dark-text child:text-sm">
                            <div className="table-cell w-[7%]"></div>
                            <div className="table-cell">Meno</div>
                            <div className="table-cell">Tím</div>
                            <div className="table-cell text-center w-10">G</div>
                            <div className="table-cell text-center w-10">A</div>
                            <div className="table-cell text-center w-10">B</div>
                            <div className="table-cell text-center">T</div>
                            <div className="table-cell text-center">Najlepší hráč</div>
                        </div>
                        {players.map((f, i) => (
                            <div className="table-row-group">
                                {i < 20 && <div key={f.ID} className="table-row child:border-t child:border-borders  child:py-3">
                                    <div className="table-cell w-[7%] text-center pr-1">{i + 1}</div>
                                    <div className="table-cell w-1/4 pr-2 whitespace-pre  "><span className="md:hidden">{f.first_name.charAt(0)}.</span><span className="hidden md:inline">{f.first_name}</span> {f.last_name}</div>
                                    <div className="table-cell md:w-1/3 text-dark-text">{f.team_name}</div>
                                    <div className="table-cell text-center w-10">{f.goals}</div>
                                    <div className="table-cell text-center w-10">{f.assists}</div>
                                    <div className="table-cell text-center w-10">{f.goals + f.assists}</div>
                                    <div className="table-cell w-10 text-center whitespace-pre">{f.penalties}'</div>
                                    <div className="table-cell w-10 text-center whitespace-pre">{f.best_player}x</div>
                                </div>}
                            </div>
                        ))}
                    </div>
                </div>}
                <h1 className="text-3xl mb-5"> Tímy</h1>
                {teams.length > 0 &&
                    <div className="table w-full mt-8 ">
                        <div className="table-row  child:border-borders text-dark-text child:text-sm">
                            <div className="hidden md:table-cell w-10"></div>
                            <div className="table-cell">Tím</div>
                            <div className="table-cell pr-3 md:px-3 w-24">Z</div>
                            <div className="table-cell pr-3 md:px-3 w-24">V</div>
                            <div className="table-cell pr-3 md:px-3 w-24">P</div>
                            <div className="table-cell pr-3 md:px-3 w-24">R</div>
                            <div className="hidden md:table-cell pr-3 md:px-3 w-24">G</div>
                            <div className="hidden md:table-cell pr-3 md:px-3 w-24">IG</div>
                            <div className="table-cell pr-3 md:px-3 w-24">+/-</div>
                            <div className="table-cell pr-3 md:px-3 w-24">B</div>
                            <div className="table-cell pr-3 md:px-3 w-24">TM</div>
                        </div>
                        {teams.map((f, i) => (
                            <Link to={`/teams/${f.ID}`} key={f.ID} className="table-row child:border-t child:border-borders  child:py-3">
                                <div className="hidden md:table-cell w-[7%] text-center">{i + 1}</div>
                                <div className="table-cell w-2/3">{f.name}</div>
                                <div className="table-cell pr-3 md:px-3 w-24">{f.z}</div>
                                <div className="table-cell pr-3 md:px-3 w-24">{f.v}</div>
                                <div className="table-cell pr-3 md:px-3 w-24">{f.p}</div>
                                <div className="table-cell pr-3 md:px-3 w-24">{f.r}</div>
                                <div className="hidden md:table-cell pr-3 md:px-3 w-24">{f.g}</div>
                                <div className="hidden md:table-cell pr-3 md:px-3 w-24">{f.ig}</div>
                                <div className="table-cell pr-3 md:px-3 w-24">{f.g - f.ig}</div>
                                <div className="table-cell pr-3 md:px-3 w-24">{f.b}</div>
                                <div className="table-cell pr-3 md:px-3 w-24">{f.penalties}'</div>
                            </Link>
                        ))}
                    </div>
                }
            </div>


        </div>
    );
}

export default Admin;